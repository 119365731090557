import Gauge from '@/components/Gauge';

import { Text } from '@simplywallst/ui-core';

import { formatterFactor } from '@/utilities/format';

import { Card } from '../Card';

export interface Props {
  portfolioValue: number | null;
  marketValue: number | null;
}

export const PriceToExpectedGrowth = ({
  portfolioValue,
  marketValue,
}: Props) => {
  return (
    <Card
      title="Price to Expected Growth"
      description={
        <Text color="inherit" typography="xSmall">
          The Portfolio PEG (Price/Earnings to Growth) Ratio offers a different
          perspective on your portfolio's valuation by considering not just
          earnings but also expected earnings growth. It's calculated as a
          weighted average of the PEG ratios of individual stocks in the
          portfolio, providing a balanced view of price, earnings, and growth
          potential. This ratio is particularly valuable for investors looking
          to assess whether the portfolio's growth prospects justify its current
          valuation. Use the Portfolio PEG Ratio to compare against market
          benchmarks and guide investment strategies that align with growth
          expectations.{' '}
          <a
            href="https://support.simplywall.st/hc/en-us/articles/9423775242383-Understanding-the-New-Portfolio-Analysis-Calculations"
            rel="noreferrer nofollow"
            target="_blank"
          >
            Learn more
          </a>
        </Text>
      }
    >
      <Gauge
        flip={true}
        keys={['portfolio', 'market']}
        entities={{
          portfolio: portfolioValue,
          market: marketValue,
        }}
        numTicks={5}
        title="PEG Ratio"
        from={0}
        to={4}
        formatter={formatterFactor}
      />
    </Card>
  );
};
